@import "../../../styles/abstracts/variables";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/base/typography";

.navdesktop {
  position: absolute;
  padding: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: -webkit-fill-available;
  display: none;
  visibility: hidden;
  z-index: 10;

  @include breakpoint(screen-m) {
    display: flex;
    visibility: visible;
  }

  &__link {
    font-family: Roboto;
    font-size: 3.5rem;
    margin-left: 3rem;
    color: black;
    display: inline-block;
    transition: 0.4s ease;

    &:hover {
      transform: translateY(-10px);
    }

    &:focus {
      font-style: italic;
    }
  }
}

.App:has(.home-page) {
  .navdesktop__link {
    &:hover {
      color: white;
    }
  }
}

.logo {
  font-family: philosopher;
  font-size: 5rem;

  &:hover {
    font-style: italic;
  }
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul > li {
  display: inline-block;
}

.active {
  font-style: italic;
}
