@import "../abstracts/variables";
@import "../../styles/abstracts/mixins";

// Roboto
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

// Philosopher
@import url("https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap");

// Merriweather
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

html {
  font-family: "Roboto", "arial", sans-serif;
  font-size: 62.5%; // 1rem = 10px ( calculation 10/16 )
}

body {
  font-size: 1.6rem;
  font-family: "Roboto", "arial", sans-serif;
}

h1,
h2,
h3,
h4 {
  font-family: "Philosopher", "Georgia", serif;
  color: $text-primary;
}

h1 {
  font-size: 10rem;
  font-weight: 400;
  margin: 0;
  font-family: "Philosopher", "Georgia", serif;
}

h2 {
  font-size: 2.8rem;
  font-weight: 100;
  font-family: "Merriweather", "Georgia", serif;
  margin: 0;

  @include breakpoint(screen-m) {
    font-size: 3.4rem;
  }
}

h3 {
  font-size: 2.3rem;
  font-weight: 200;
  font-family: "Merriweather", "Georgia", serif;
}

h4 {
  font-size: 2rem;
  font-weight: 200;
  margin: 4rem 0 1.6rem 0;

  @include breakpoint(screen-s) {
    font-size: 2.3rem;
    font-weight: 200;
    margin: 4rem 0 1.6rem 0;
  }

  @include breakpoint(screen-m) {
    font-size: 2.5rem;
  }
}

h5 {
  font-size: 1.6rem;
  font-weight: 200;
  font-family: "Merriweather", "Georgia", serif;
  padding: 0 0 2rem 0;
  margin: 0;

  @include breakpoint(screen-m) {
    font-size: 1.8rem;
  }
}

.link {
  text-decoration: none;
  margin: 0 0.5rem 0.1rem 0.5rem;
  font-weight: 500;
}

p {
  font-size: 1.6rem;
  font-family: "Roboto", "arial", sans-serif;
  padding: 0;
  margin: 0;
  line-height: 2.5rem;

  @include breakpoint(screen-l) {
    font-size: 1.9rem;
  }

  @include breakpoint(screen-xxl) {
    font-size: 2rem;
  }
}
