@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/variables";

.bg {
  height: 100%;
  width: 100%;
  background-color: #f3f3f3;

  @include breakpoint(screen-l) {
    height: 100vh;
    width: 100vw;
    background-image: url(../../source/images/background/background-about-grey-xl.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  @include breakpoint(screen-xxl) {
    background-image: url(../../source/images/background/background-white-xxl.svg);
  }
}

.about {
  margin-bottom: 5rem;
  padding: 40% 5rem 0rem 5rem;

  @include breakpoint(screen-s) {
    padding: 19% 5rem 5rem 5rem;
  }

  @include breakpoint(screen-m) {
    padding: 20% 5rem 5rem 5rem;
  }

  @include breakpoint(screen-l) {
    padding: 15% 5rem 5rem 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    row-gap: 4rem;
  }

  @include breakpoint(screen-xl) {
    padding: 10% 5rem 5rem 5rem;
    column-gap: 10rem;
  }

  @include breakpoint(screen-xxl) {
    padding: 13% 5rem 5rem 5rem;
    font-size: 3.4rem;
    width: 1400px;
    margin: auto;
  }

  &-item {
    margin-bottom: 3.5rem;

    @include breakpoint(screen-l) {
      margin-bottom: 0rem;
    }
  }

  &-title {
    margin-bottom: 0.5rem;
    font-size: 2.2rem;

    @include breakpoint(screen-s) {
      margin-bottom: o;
    }

    @include breakpoint(screen-s) {
      margin-bottom: 1rem;
    }

    @include breakpoint(screen-l) {
      margin-bottom: 1rem;
      font-size: 2.8rem;
    }
  }

  &-item1 {
    grid-row: 1/2;
    grid-column: 1/2;
    width: 100%;
  }

  &-item2 {
    grid-row: 1/2;
    grid-column: 3/4;
    width: 100%;
  }

  &-item3 {
    grid-row: 2/3;
    grid-column: 2/3;
    width: 100%;
  }

  &-item4 {
    grid-row: 3/4;
    grid-column: 1/2;
    width: 100%;
  }
}

.between {
  margin-top: 2rem;
}

.icon {
  height: 4rem;
  width: 4rem;
  padding-right: 1rem;
  padding-top: 3rem;
}

.icons {
  position: absolute;
  left: 5rem;
  bottom: 5rem;
}

.platforms {
  display: flex;
  padding-top: 1.4rem;
  align-items: center;

  @include breakpoint(screen-l) {
    padding-top: 1.6rem;
  }

  &-item {
    padding-right: 1.4rem;
  }

  &-link {
    font-weight: 500;
    font-size: 1.7rem;

    @include breakpoint(screen-l) {
      font-size: 1.9rem;
    }

    &:hover {
      font-style: italic;
      color: #6666d2;
    }
  }
}

.eLink {
  width: 1.5rem;
  height: 1rem;
  padding-left: 0.3rem;
}
