@import "../../../styles/abstracts/variables";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/base/typography";

.navmobile {
  overflow: hidden;

  &--open {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    position: absolute;
    z-index: 3;
    animation: 1s navmenuOpen ease;
    height: 100vh;
    width: 100vw;
  }

  @keyframes navmenuOpen {
    0% {
      right: -100%;
    }
    100% {
      right: 0%;
    }
  }

  &--closed-no-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: absolute;
    background-color: $primary;
    z-index: 3;
    padding: 5rem;
    visibility: hidden;
    box-sizing: border-box;
  }

  &--closed {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: absolute;
    background-color: $primary;
    z-index: 3;
    padding: 5rem;
    visibility: hidden;
    box-sizing: border-box;
    animation: 1s navmenuClosed ease;
  }

  @keyframes navmenuClosed {
    0% {
      right: 0;
    }

    100% {
      visibility: visible;
      right: -100%;
    }
  }

  ._logo_hamburger {
    position: absolute;
    z-index: 3;
    padding: 5rem;
    width: 100%;
    box-sizing: border-box;
    animation: 1s navmenuClosed ease;
  }

  &__wrapper {
    &--closed {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &--open {
      padding: 5rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      height: 100vh;
      width: 100vw;
      overflow: hidden;
    }
  }

  &__list {
    background-color: $primary;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__link {
    color: white;
    font-size: large;
    font-family: philosopher;
    font-size: 5rem;
    text-align: center;
    padding: 0.8rem 0;

    @include breakpoint(screen-s) {
      font-size: 6rem;
    }

    @include breakpoint(screen-m) {
      font-size: 7rem;
    }
  }
}

.logo {
  font-family: philosopher;
  font-size: 5rem;
  position: absolute;
  top: 5rem;
  left: 5rem;

  &:hover {
    font-style: italic;
  }
}

.hamburger-react-wrapper {
  position: absolute;
  right: 5rem;
  top: 5rem;
  z-index: 20;
}
