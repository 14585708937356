@import "../../styles/abstracts/mixins";

.item-container {
  justify-content: center;
  position: absolute;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 5rem;
  padding: 23% 5rem 5rem 5rem;

  &--navopen {
    position: fixed;
  }

  @include breakpoint(screen-m) {
    padding: 18% 5rem 5rem 5rem;
  }

  @include breakpoint(screen-l) {
    padding: 20% 5rem 5rem 5rem;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
    column-gap: 3rem;
  }

  @include breakpoint(screen-xl) {
    padding: 10% 5rem 5rem 5rem;
  }
}

img {
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.img-wrapper {
  display: inline-block;
  overflow: hidden;
}

.img-wrapper img {
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  vertical-align: middle;
}

.item:hover .img-wrapper img {
  -webkit-transform: scale(1.05); /* Safari and Chrome */
  -moz-transform: scale(1.05); /* Firefox */
  -ms-transform: scale(1.05); /* IE 9 */
  -o-transform: scale(1.05); /* Opera */
  transform: scale(1.05);
  cursor: pointer;
}

.item:hover .item-title {
  font-style: italic;
}
