@import "../../styles/layout/page";
@import "../../styles/abstracts/mixins";
@import "~video-react/styles/scss/video-react";

.case-container {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: auto;
  padding: 40% 5rem 5rem 5rem;

  @include breakpoint(screen-s) {
    padding: 25% 5rem 5rem 5rem;
  }

  @include breakpoint(screen-m) {
    padding: 20% 5rem 5rem 5rem;
  }

  @include breakpoint(screen-l) {
    padding: 15% 5rem 5rem 5rem;
  }
}

.case-wrapper {
  padding-bottom: 4rem;
}

.caselink-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3rem;
}

.case-title {
  margin-bottom: 2rem;
}

.wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.website-link {
  color: blue;
  border-bottom: 1px solid blue;
  padding-bottom: 1px;
}
