@import "../../styles/abstracts/mixins";

.App {
  overflow: hidden;
}

.home {
  display: flex;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  background-color: #f3f3f3;
  overflow: hidden;

  &__title {
    z-index: 2;
    position: absolute;
    padding-left: 5rem;
    font-size: 2rem;
    position: absolute;
  }

  @include breakpoint(screen-l) {
    height: 100vh;
    width: 100vw;
    background-image: url(../../source/images/background/home-grey-xl.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  @include breakpoint(screen-xxl) {
    background-image: url(../../source/images/background/home-grey-xxl-new.svg);
  }

  h1 {
    z-index: 10;
    font-size: 9rem;

    @include breakpoint(screen-s) {
      font-size: 13rem;
    }

    @include breakpoint(screen-m) {
      font-size: 18rem;
    }

    @include breakpoint(screen-l) {
      font-size: 22rem;
    }

    @include breakpoint(screen-xl) {
      font-size: 28rem;
    }

    @include breakpoint(screen-xxl) {
      font-size: 29rem;
    }
  }
}

.mobilebg-home {
  position: absolute;
  bottom: 0;
  z-index: 1;
}
