// breakpoints
@mixin breakpoint($point) {
  @if $point == screen-xs {
    @media screen and (min-width: 320px) {
      @content;
    }
  } @else if $point == screen-s {
    @media screen and (min-width: 600px) {
      @content;
    }
  } @else if $point == screen-m {
    @media screen and (min-width: 740px) {
      @content;
    }
  } @else if $point == screen-l {
    @media screen and (min-width: 1000px) {
      @content;
    }
  } @else if $point == screen-xl {
    @media screen and (min-width: 1100px) {
      @content;
    }
  } @else if $point == screen-xxl {
    @media screen and (min-width: 2000px) {
      @content;
    }
  }
}
